.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;

  .login-form {
    padding: 32px;
    background: #fff;
    border-radius: 4px;
    width: 350px;
    position: relative;

    .login-icon{
      margin: 20px auto;
      width: 80px;
      height: 80px;
      display: block;
      border: 2px solid;
      border-radius: 24px;
    }

    .forgot-pwd-btn {
      position: absolute;
      right: 0;
    }

    .login-btn {
      width: 100%;
    }
  }
}

.beian-footer{
  position: fixed;
  bottom: 0;
}
