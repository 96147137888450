@import "login";

html {
  //firefox
  scrollbar-width: none;
  height: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  height: 100%;
  overflow: auto;
}

.profile {
  width: 100%;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  margin: 20px auto;
  cursor: pointer;

  &:hover {
    color: white;
  }

}

.profile-icon {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  display: block;
  border-radius: 24px;
}

.ant-table-cell, .ant-table-cell, .ant-descriptions-item-label, .ant-descriptions-item-content {
  text-align: center !important;
}

.table-row {
  &:nth-child(odd) {
    background-color: #fff;
  }

  &:nth-child(even) {
    background-color: rgb(250, 250, 250);
  }
}

.margin-top-16{
  margin-top: 16px;
}
